import { NextPage } from "next";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageHead from "../components/PageHead";
import { useUser } from "../components/fetchers/useUser";
import { MainProps } from "./_app";

const loginServices = [
  {
    name: "Twitch",
    bgColor: "#6441a5",
    slug: "twitch",
    logo: "/logos/twitch.png",
  },
];

const Login: NextPage<MainProps> = (props: MainProps) => {
  const { user, isLoading } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (user?.id) {
      router.push("/utils");
    }
  }, [user]);

  return (
    <div className="flex flex-1 h-screen w-full flex-col scroll-smooth">
      <PageHead page="LOGIN" link="/login" />
      <Header changeTheme={props.changeTheme} />
      <div className="flex h-full bg-base-100 justify-center items-center">
        <div className="flex flex-col bg-base-200 rounded-3xl w-4/5 md:w-1/3 justify-center items-center">
          <h1 className="text-center text-4xl pt-10 pb-10">Log in</h1>
          {isLoading && (
            <div className="self-center ">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          )}
          {!isLoading && loginServices.map((service) => (
            <div
              className="flex w-3/5 h-full rounded-lg justify-center btn mb-7 hover:scale-105"
              style={{ backgroundColor: service.bgColor }}
              onClick={() => signIn(service.slug)}
              key={`method-${service.slug}`}>
              {/* <Image src={service.logo} height={50} width={50} alt="Logo" /> */}
              <p className="text-2xl text-center">{service.name}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
